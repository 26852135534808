import { QueueFields } from '../../domain/models/content-fields'
import {
  ConversationalApp,
  ConversationalAppProviderAccount,
  HubtypeUser,
  KnowledgeSourceResponse,
  OrganizationAiIntent,
  OrganizationAiModel,
  OrganizationKnowledgeBases,
  OrganizationKnowledgeSource,
  OrganizationProject,
  OrganizationQueue,
} from '../../domain/models/organization-models'
import { OrganizationContents } from '../../UI/types'
import { hasTmpNewKnowledgeSourcesFeature } from '../../UI/utils/feature-flags'
import { HubtypeApi } from './hubtype-api'

export class HubtypeService {
  static async getUserData(
    authToken: string,
    userId: string
  ): Promise<HubtypeUser | undefined> {
    const user = await HubtypeApi.get(authToken, `users/${userId}/`, 'v2')
    if (!user) return undefined
    return new HubtypeUser(
      user.id,
      user.email,
      user.username,
      user.first_name,
      user.last_name,
      user.pic
    )
  }

  static async getConversationalApp(
    authToken: string,
    botId: string
  ): Promise<ConversationalApp> {
    const botData = await HubtypeApi.get(
      authToken,
      `bots/${botId}/?include_test_providers=${true}`,
      'v1'
    )
    if (!botData) return {}
    return new ConversationalApp(
      botData.id,
      botData.name,
      botData.provider_accounts.map((provider: any) => {
        return new ConversationalAppProviderAccount(
          provider.id,
          provider.provider,
          provider.name,
          provider.is_test,
          provider.is_active,
          provider.phone_number,
          provider.netlify_url
        )
      }),
      botData.flow_builder_settings
    )
  }

  static async getOrganizationAiIntents(
    authToken: string,
    modelId: string
  ): Promise<OrganizationAiIntent[]> {
    const data = await HubtypeApi.get(
      authToken,
      `babel/projects/${modelId}/intents/`,
      'v1',
      { page_size: 100 }
    )
    if (!data) return []
    return data.results.map((intent: any) => ({
      id: intent.id,
      name: intent.name,
    }))
  }

  static async getOrganizationContents(
    authToken: string,
    botId: string
  ): Promise<OrganizationContents> {
    const projects = await this.getOrganizationProjects(authToken)
    const aiModels = await this.getOrganizationAiModels(authToken)
    const featureFlags = await this.getOrganizationFeatureFlags(authToken)
    const knowledgeSources = await this.getOrganizationKnowledgeSources(
      authToken
    )
    const conversationalApp = await this.getConversationalApp(authToken, botId)
    return {
      projects,
      aiModels,
      featureFlags,
      knowledgeSources,
      conversationalApp,
    }
  }

  private static async getOrganizationFeatureFlags(
    authToken: string
  ): Promise<Record<string, boolean>> {
    const data = await HubtypeApi.get(authToken, 'my-organization/')
    if (!data) return {}
    return data.feature_flags
  }

  private static async getOrganizationProjects(
    authToken: string
  ): Promise<OrganizationProject[]> {
    const data = await HubtypeApi.get(authToken, 'projects/', 'v2')
    if (!data) return []
    return data.results.map((project: any) => ({
      id: project.id,
      name: project.name,
      queues: project.queues.map((queue: OrganizationQueue) =>
        QueueFields.fromHubtype(queue)
      ),
    }))
  }

  private static async getOrganizationAiModels(
    authToken: string
  ): Promise<OrganizationAiModel[]> {
    const data = await HubtypeApi.get(authToken, 'babel/projects/')
    if (!data) return []
    return data.results.map((model: any) => ({
      id: model.id,
      name: model.name,
    }))
  }

  private static async getOrganizationKnowledgeSources(
    authToken: string
  ): Promise<OrganizationKnowledgeSource[]> {
    const orgFeatureFlags = await this.getOrganizationFeatureFlags(authToken)
    const hasNewSources = hasTmpNewKnowledgeSourcesFeature(orgFeatureFlags)

    const responseData: OrganizationKnowledgeBases = await HubtypeApi.get(
      authToken,
      `ai/knowledge_bases/`
    )
    const mainKnowledgeBase = responseData?.results.find(
      knowledgeBase => knowledgeBase.is_main
    )
    if (!mainKnowledgeBase) return []
    if (hasNewSources) {
      return this.getNewKnowledgeSources(authToken, mainKnowledgeBase.id)
    }
    const data = mainKnowledgeBase?.knowledge_sources
      .filter(source => source.is_active)
      .map(source => ({
        id: source.id,
        name: source.file_name,
        fileType: source.file_name.split('.').pop(),
      }))
      .sort((a, b) => a.name.localeCompare(b.name))

    if (!data) return []
    return data
  }

  private static async getNewKnowledgeSources(
    authToken: string,
    knowledgeBaseId: string
  ): Promise<OrganizationKnowledgeSource[]> {
    const responseData = await HubtypeApi.get(
      authToken,
      `ai/knowledge_bases/${knowledgeBaseId}/sources/`
    )
    const knowledgeSources = responseData.results as KnowledgeSourceResponse[]
    const data = knowledgeSources
      .filter(source => source.active_extraction_job)
      .map(source => ({
        id: source.id,
        name: source.name,
        fileType: source.active_extraction_job?.file_name.split('.').pop(),
      }))
      .sort((a, b) => a.name.localeCompare(b.name))

    if (!data) return []
    return data
  }
}
