import { Position } from 'reactflow'

import { StartFields } from '../../../../domain/models/content-fields'
import { FALLBACK_FLOW } from '../../../constants'
import { NodeHandleType } from '../constants'
import { HandleType } from '../handle-styles'
import { StyledHandle } from '../node-components'

interface StartNodeHandleProps {
  hasPreviousFlows: boolean
  currentFlowId: string
  data: StartFields
}

export const StartNodeHandle = ({
  hasPreviousFlows,
  currentFlowId,
  data,
}: StartNodeHandleProps) => {
  return (
    <StyledHandle
      handleType={
        hasPreviousFlows ? HandleType.START_FLOW : HandleType.START_CONVERSATION
      }
      type={NodeHandleType.Source}
      position={Position.Right}
      id={`start|${currentFlowId}_${data.id}`}
      isConnectable={currentFlowId !== FALLBACK_FLOW.id}
      isConnectableWithBotAction={false}
    />
  )
}
