import styled, { css } from 'styled-components'

import { HelperText } from '../../base-styles'
import { COLORS } from '../../colors'
import { HtBtnType } from '../../ht-button/ht-button'
import { StyledButton } from '../../ht-button/ht-button-styles'
import { ellipsis, textBodySmall, TextSmallBold } from '../../typography'
import { Size } from '../../variants'

export const StyledDropdownButton = styled(StyledButton)<{
  $isReadOnly?: boolean
  $isEmpty?: boolean
}>`
  ${props => props.$isReadOnly && readOnlyButton};
  color: ${props =>
    props.$isEmpty && props.$type === HtBtnType.TERTIARY && COLORS.N500};

  > :first-child {
    width: 100% !important;
  }

  span {
    display: inline;
    margin-right: auto;
  }
`

const readOnlyButton = css`
  background: ${COLORS.N50};
  color: ${COLORS.N900};
  border: solid 1px ${COLORS.N400};

  &:disabled {
    color: ${COLORS.N900};
    border-color: ${COLORS.N400};
  }
`

export const StyledList = styled.ul`
  padding: 0px;
  list-style: none;
  overflow-y: auto;
  margin: 0px;

  &:focus {
    outline: 0;
  }
`

export const StyledListItem = styled.li`
  ${textBodySmall};
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  color: ${COLORS.N900};
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;

  &[aria-disabled='true'] {
    color: ${COLORS.N300};
  }
  &:focus {
    outline: 0;
    background: ${COLORS.N50};
  }
  &[aria-selected='true'] {
    background: ${COLORS.L50};
  }
`

export const ListContainer = styled.div<{
  $maxListHeight?: string
  $listWidth?: string
  $hasItemsWithBorder?: boolean
  $size?: Size.MEDIUM | Size.LARGE
}>`
  width: ${props => props.$listWidth || '240px'};
  background: ${COLORS.WHITE};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%),
    0px 1px 10px rgb(0 0 0 / 12%);
  border-radius: 8px;
  padding: 8px 0px;
  margin: 2px 0px;

  ${StyledList} {
    max-height: ${props => props.$maxListHeight || '200px'} !important;
  }

  ${StyledListItem} {
    border-top: ${props =>
      props.$hasItemsWithBorder && `1px solid ${COLORS.N100}`};
    padding: ${props => props.$size === Size.LARGE && '12px 16px'};
  }
`

export const DropdownContainer = styled.div<{ width?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => props.width};

  ${TextSmallBold}:first-child {
    margin-bottom: 2px;
  }

  ${HelperText} {
    margin-top: 4px;
  }

  span {
    ${ellipsis};
  }
`

export const StyledEditableItem = styled.div<{ $isLongItem?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  margin: -8px -16px;
  svg {
    color: ${COLORS.N500};
  }

  > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: ${props => (props.$isLongItem ? 'break-all' : 'normal')};
    color: ${COLORS.N900};
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  > div:last-child {
    display: flex;
    visibility: hidden;
  }

  &:hover {
    > div:last-child {
      visibility: visible;
    }
  }
`

export const StyledSelectHeader = styled.div`
  padding: 0px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${COLORS.N500};
`

export const Aux = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
