import { FeedbackType } from '../../../../domain/feedback'
import { Icon } from './icons-list'

export const getIconFromFeedbackType = (feedbackType: FeedbackType): Icon => {
  switch (feedbackType) {
    case FeedbackType.SUCCESS:
      return Icon.CIRCLE_CHECK
    case FeedbackType.INFORM:
      return Icon.CIRCLE_INFO
    case FeedbackType.CONNECTION_ISSUE:
      return Icon.CLOUD_SLASH
    default:
      return Icon.CIRCLE_XMARK
  }
}

export const getIconFromFileType = (fileType?: string): Icon => {
  switch (fileType) {
    case 'pdf':
      return Icon.FILE_PDF
    case 'doc':
    case 'docx':
      return Icon.FILE_WORD
    case 'xls':
    case 'xlsx':
      return Icon.FILE_EXCEL
    case 'csv':
      return Icon.FILE_CSV
    default:
      return Icon.FILE
  }
}
