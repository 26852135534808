export enum Country {
  AT = 'Austria',
  BG = 'Bulgaria',
  CA = 'Canada',
  CH = 'Switzerland',
  CN = 'China',
  CO = 'Colombia',
  CZ = 'Czech Republic',
  DE = 'Germany',
  DK = 'Denmark',
  EE = 'Estonia',
  ES = 'Spain',
  FI = 'Finland',
  FR = 'France',
  GB = 'United Kingdom',
  GE = 'Georgia',
  GR = 'Greece',
  HR = 'Croatia',
  HU = 'Hungary',
  ID = 'Indonesia',
  IE = 'Ireland',
  IN = 'India',
  IS = 'Iceland',
  IT = 'Italy',
  JP = 'Japan',
  KR = 'Korea',
  LK = 'Sri Lanka',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  LV = 'Latvia',
  MT = 'Malta',
  MX = 'Mexico',
  MY = 'Malaysia',
  NL = 'Dutch',
  NO = 'Norway',
  PL = 'Poland',
  PT = 'Portugal',
  RO = 'Romania',
  RS = 'Serbia',
  RU = 'Russia',
  SA = 'Saudi Arabia',
  SE = 'Sweden',
  SI = 'Slovenia',
  SK = 'Slovakia',
  TH = 'Thailand',
  TR = 'Turkey',
  UA = 'Ukraine',
  US = 'United States',
  VN = 'Vietnam',
  ZA = 'South Africa',
}
