import { useState } from 'react'

import { TrackEventName, useAnalytics } from '../../analytics'
import {
  BUILD_PANEL_WIDTH,
  FALLBACK_FLOW,
  KNOWLEDGE_BASE_FLOW,
  MAIN_FLOW,
} from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { Flow, PopupType } from '../../types'
import { ViewportAnimator } from '../../utils/viewport-animator'
import {
  HtBtnType,
  HtButton,
  HtSearchBar,
  HtSelectDropdown,
  Size,
} from '../base'
import { DefaultFlowItem } from './default-flow-item'
import { EditableFlowItem } from './flow-item'
import {
  StyledFlowSelector,
  StyledFooter,
  StyledHeader,
} from './flow-selector-styles'

export const FlowSelector = (): JSX.Element => {
  const analytics = useAnalytics()
  const { state, selectFlow, setPopupContent } = useFlowBuilderSelector(
    ctx => ctx
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [flowFilter, setFlowFilter] = useState<string>('')

  const onSelectFlow = (flow: Flow) => {
    analytics.trackEvent(TrackEventName.FLOW_OPEN, {
      flow_name: flow.name,
      origin: 'list',
    })
    selectFlow(flow.id)
    ViewportAnimator.centerStartNode(state)
  }

  const addFlow = (evt: React.MouseEvent) => {
    evt.stopPropagation()
    setIsOpen(false)
    setPopupContent({ type: PopupType.ADD_FLOW })
  }

  const isDefaultFlow = (flow: Flow) =>
    [MAIN_FLOW.id, FALLBACK_FLOW.id, KNOWLEDGE_BASE_FLOW.id].includes(flow.id)

  return (
    <StyledFlowSelector>
      <HtSelectDropdown
        isOpen={isOpen}
        label={'Flows'}
        hideLabel
        data={state.flows}
        selectedKey={state.currentFlowId}
        width={`${BUILD_PANEL_WIDTH}px`}
        listProps={{
          listWidth: `${BUILD_PANEL_WIDTH}px`,
          maxListHeight: 'calc(100vh - 210px)',
        }}
        btnProps={{ type: HtBtnType.PRIMARY_GREY, size: Size.SMALL }}
        onOpenChange={setIsOpen}
        onChange={flow => flow && onSelectFlow(flow)}
        filerValue={flowFilter}
        header={
          <StyledHeader>
            <HtSearchBar
              placeholder='Search flows'
              size={Size.SMALL}
              onFilterChange={setFlowFilter}
            />
          </StyledHeader>
        }
        footer={
          state.isReadOnly ? undefined : (
            <StyledFooter>
              <HtButton
                size={Size.TINY}
                type={HtBtnType.TERTIARY}
                onClick={addFlow}
              >
                Add flow
              </HtButton>
            </StyledFooter>
          )
        }
      >
        {flow =>
          isDefaultFlow(flow) ? (
            <DefaultFlowItem flow={flow} />
          ) : (
            <EditableFlowItem
              key={flow.id}
              flow={flow}
              closeFlowSelector={() => setIsOpen(false)}
            />
          )
        }
      </HtSelectDropdown>
    </StyledFlowSelector>
  )
}
