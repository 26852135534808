import { LocaleCode } from '../../domain/models/locales/code'
import { Locale } from '../../domain/models/locales/locale'
import { SUPPORTED_LOCALES } from '../../domain/models/locales/supported-locales'
import { NodeTypes } from '../types'

export const stringArrayToLocaleArray = (localesString: string[]): Locale[] => {
  return localesString.reduce((acc: Locale[], localeString) => {
    const locale = stringToLocale(localeString)
    if (locale) acc.push(locale)
    return acc
  }, [])
}

export const stringToLocale = (localeString: string): Locale | undefined => {
  return Object.values(SUPPORTED_LOCALES).find(l => l.code === localeString)
}

export const getErrorsInLocales = (
  nodes: NodeTypes[],
  locales: Locale[]
): Record<LocaleCode, number> => {
  const localeCodes = locales.map(locale => locale.code)
  return nodes.reduce((acc, node) => {
    node.data.getLocalesWithErrors(localeCodes).forEach(locale => {
      acc[locale] = acc[locale] ? acc[locale] + 1 : 1
    })
    return acc
  }, {} as Record<string, number>)
}
