import styled, { css } from 'styled-components'

import { COLORS, GRADIENT } from '../colors'
import {
  iconMediumSize,
  iconMicroSize,
  iconSmallSize,
} from '../ht-icon/ht-icon-styles'
import { Size } from '../variants'
import { textBody, textBodySmall, textSmall } from './../typography'
import { HtBtnSize, HtBtnType } from './ht-button'

export const StyledButton = styled.button<{
  $type: HtBtnType
  $size: HtBtnSize
  $width?: string
}>`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  width: ${props => props.$width || 'fit-content'};
  word-break: break-word;
  text-align: initial;

  ${props => props.$size === Size.LARGE && sizeLarge};
  ${props => props.$size === Size.MEDIUM && sizeMedium};
  ${props => props.$size === Size.SMALL && sizeSmall};
  ${props => props.$size === Size.TINY && sizeTiny};

  ${props => props.$type === HtBtnType.PRIMARY && btnPrimary};
  ${props => props.$type === HtBtnType.SECONDARY && btnSecondary};
  ${props => props.$type === HtBtnType.SECONDARY_WHITE && btnSecondaryWhite};
  ${props => props.$type === HtBtnType.TERTIARY && btnTertiary};
  ${props => props.$type === HtBtnType.DANGER && btnDanger};
  ${props => props.$type === HtBtnType.PRIMARY_GREY && btnPrimaryGrey};
  ${props => props.$type === HtBtnType.BORDERLESS && btnBorderless};
  ${props => props.$type === HtBtnType.BORDERLESS_BLACK && btnBorderlessBlack};
  ${props => props.$type === HtBtnType.AI && btnAi};

  > div:first-of-type {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    word-break: break-word;
    text-align: initial;
  }

  &:disabled {
    cursor: initial;
  }

  &:focus {
    outline: 0;
  }

  span {
    display: flex;
    align-items: center;
  }
`

// css button type
const btnPrimary = css`
  background: ${COLORS.L500};
  color: ${COLORS.WHITE};

  &:disabled {
    background: ${COLORS.L200};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.L600};
  }
`

const btnPrimaryGrey = css`
  background: ${COLORS.N50};
  color: ${COLORS.N900};

  &:disabled {
    background: ${COLORS.N50};
    color: ${COLORS.N300};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.N100};
  }
`

const btnSecondary = css`
  background: transparent;
  color: ${COLORS.L500};
  border: solid 1px ${COLORS.L500};

  &:disabled {
    color: ${COLORS.L300};
    border-color: ${COLORS.L300};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.L100};
  }
`

const btnSecondaryWhite = css`
  background: transparent;
  color: ${COLORS.WHITE};
  border: solid 1px ${COLORS.WHITE};

  &:disabled {
    color: ${COLORS.L300};
    border-color: ${COLORS.L300};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.L600};
  }
`

const btnTertiary = css`
  background: transparent;
  color: ${COLORS.N900};
  border: solid 1px ${COLORS.N400};

  &:disabled {
    color: ${COLORS.N300};
    border-color: ${COLORS.N200};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.N100};
  }

  svg {
    color: ${COLORS.N500};
  }
`

const btnDanger = css`
  background: ${COLORS.R600};
  color: ${COLORS.WHITE};

  &:disabled {
    background: ${COLORS.R200};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.R700};
  }
`

const btnBorderless = css`
  background: transparent;
  color: ${COLORS.L500};

  &:disabled {
    color: ${COLORS.L300};
  }

  &:hover:not([disabled]) {
    background: ${COLORS.L50};
  }
`

const btnBorderlessBlack = css`
  background: transparent;
  color: ${COLORS.N900};
  padding: 0;

  &:disabled {
    color: ${COLORS.N300};
  }
`

const btnAi = css`
  color: white;
  background: ${GRADIENT.WHITE} padding-box, ${GRADIENT.AI_LIGHT} border-box;
  border: 1px solid transparent;

  span {
    background: ${GRADIENT.AI_LIGHT};
    background-clip: text;
    -webkit-text-fill-color: transparent;

    circle {
      stroke: ${COLORS.P700};
    }
    svg {
      color: ${COLORS.L500};
    }
  }

  &:disabled {
    background: ${GRADIENT.WHITE} padding-box, ${GRADIENT.L100} border-box;
  }

  &:hover:not([disabled]) {
    background: ${GRADIENT.L50} padding-box, ${GRADIENT.AI_LIGHT} border-box;
  }
`

// css button size
const sizeLarge = css`
  height: 48px;
  padding: 12px 24px;
  border-radius: 8px;
  ${textBody};

  svg {
    ${iconMediumSize}
  }
`

const sizeMedium = css`
  height: 45px;
  padding: 12px 16px;
  border-radius: 8px;
  ${textBodySmall};

  svg {
    ${iconSmallSize}
  }
`

const sizeSmall = css`
  height: 37px;
  padding: 8px 12px;
  border-radius: 8px;
  ${textBodySmall};

  svg {
    ${iconSmallSize}
  }
`

const sizeTiny = css`
  height: 26px;
  padding: 4px 8px;
  border-radius: 4px;
  gap: 4px;

  span {
    gap: 4px !important;
  }

  ${textSmall};

  svg {
    ${iconMicroSize}
  }
`
