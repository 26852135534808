import { memo } from 'react'
import { Position } from 'reactflow'

import { FALLBACK_MESSAGE_1, FALLBACK_MESSAGE_2 } from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { FallbackNodeProps } from '../../types'
import { TextSmallBold } from '../base'
import { Icon } from '../base'
import { NodeHandleType } from './constants'
import { HandleType } from './handle-styles'
import { Condition, NodeHeader, StyledHandle } from './node-components'
import { Border, MessageNode, StyledList } from './node-styles'

const FallbackNode = ({
  data,
  selected,
  id,
}: FallbackNodeProps): JSX.Element => {
  // TODO: remove knowledge base v1
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const knowledgeBaseFollowup = data.edges.find(
    edge => edge.sourceHandle === `${id}-knowledge_base_followup`
  )
  // end of TODO
  return (
    <MessageNode $isSelected={selected}>
      <NodeHeader
        data={data}
        title={'Fallback messages'}
        icon={Icon.LIFE_RING}
      ></NodeHeader>
      <StyledHandle
        id={id}
        handleType={HandleType.TARGET}
        type={NodeHandleType.Target}
        position={Position.Left}
        isConnectable={false}
        isConnectableWithBotAction={false}
      />
      {/* TODO: remove knowledge base v1 */}
      {state.isKnowledgeBaseActive && knowledgeBaseFollowup && (
        <StyledList $hasMarginTop>
          <TextSmallBold>If knowledge base AI is able to respond</TextSmallBold>
          <Condition
            id={`${id}-knowledge_base_followup`}
            border={Border.BOTTOM}
            handleType={HandleType.FOLLOWUP_KNOWLEDGE_BASE}
          >
            Follow-up message
          </Condition>
        </StyledList>
      )}
      <StyledList
        $hasMarginTop={!state.isKnowledgeBaseActive || !knowledgeBaseFollowup}
      >
        {state.isKnowledgeBaseActive && knowledgeBaseFollowup && (
          <TextSmallBold>
            If knowledge base AI is not able to respond
          </TextSmallBold>
        )}
        {/* end of TODO */}
        <Condition
          id={`${id}-${FALLBACK_MESSAGE_1}`}
          handleType={HandleType.FALLBACK1}
          border={null}
        >
          1st message
        </Condition>
        <Condition
          id={`${id}-${FALLBACK_MESSAGE_2}`}
          handleType={HandleType.SMALL_CONDITION}
          border={Border.TOP}
        >
          2nd message
        </Condition>
      </StyledList>
    </MessageNode>
  )
}

export default memo(FallbackNode)
