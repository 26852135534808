import { Edge } from 'reactflow'

import { HtNodeLink } from '../../domain/models/cms/hubtype/common'
import { HtFallbackNode } from '../../domain/models/cms/hubtype/fallback'
import { HtFunctionNode } from '../../domain/models/cms/hubtype/function'
import { HtContentType } from '../../domain/models/cms/hubtype/node-types'
import {
  HtNodeComponent,
  HtNodeWithContent,
} from '../../domain/models/cms/hubtype/nodes'
import { ContentType } from '../../domain/models/content-fields'
import { hasFollowUpHandle } from '../../UI/components/edges/edge-utils'
import { FALLBACK_MESSAGE_1, FALLBACK_MESSAGE_2 } from '../../UI/constants'
import { NodeTypes } from '../../UI/types'

interface FieldWithTarget {
  id: string
  target?: HtNodeLink
}

const NODES_WITH_TARGET = [
  HtContentType.HANDOFF,
  HtContentType.KEYWORD,
  HtContentType.INTENT,
  HtContentType.SMART_INTENT,
]

export class LinkFactory {
  public addNodeLink(edges: Edge[], node: HtNodeWithContent): void {
    const link = this.getEdgeWithFollowupLink(edges, node)
    if (NODES_WITH_TARGET.includes(node.type)) {
      node.target = link
    } else {
      node.follow_up = link
    }
  }

  public addFieldLink<T extends FieldWithTarget>(node: NodeTypes, field: T): T {
    field.target = this.getEdgeTarget(node, field)
    return field
  }

  public addFallbackLinks(edges: Edge[], node: HtFallbackNode): void {
    const firstLink = this.getEdgeWithSuffixLink(
      edges,
      node,
      FALLBACK_MESSAGE_1
    )
    const secondLink = this.getEdgeWithSuffixLink(
      edges,
      node,
      FALLBACK_MESSAGE_2
    )
    // TODO: remove knowledge base v1
    const knowledgeBaseFollowupLink = this.getEdgeWithSuffixLink(
      edges,
      node,
      'knowledge_base_followup'
    )
    node.content.knowledge_base_followup = knowledgeBaseFollowupLink
    // end of TODO
    node.content.first_message = firstLink
    node.content.second_message = secondLink
  }

  public addConditionLink(
    edges: Edge[],
    node: HtFunctionNode,
    edgeSuffix: string,
    name?: string | boolean | number
  ): void {
    const target = this.getEdgeWithSuffixLink(edges, node, edgeSuffix)
    const resultMapping = { result: name ?? edgeSuffix, target }
    node.content.result_mapping.push(resultMapping)
  }

  private getEdgeWithFollowupLink(
    edges: Edge[],
    node: HtNodeComponent
  ): HtNodeLink | undefined {
    const foundEdge = edges.find(
      edge => edge.source === node.id && hasFollowUpHandle(edge)
    )
    return this.getLinkFromEdge(foundEdge)
  }

  private getEdgeTarget(
    node: NodeTypes,
    field: FieldWithTarget
  ): HtNodeLink | undefined {
    const foundEdge = node.data.edges.find(
      edge => edge.source === node.id && edge.sourceHandle === field.id
    )
    return this.getLinkFromEdge(foundEdge)
  }

  private getEdgeWithSuffixLink(
    edges: Edge[],
    node: HtNodeComponent,
    edgeSuffix: string
  ): HtNodeLink | undefined {
    const foundEdge = edges.find(
      edge =>
        edge.source === node.id && edge.sourceHandle?.includes(`-${edgeSuffix}`)
    )
    return this.getLinkFromEdge(foundEdge)
  }

  private getLinkFromEdge(edge?: Edge): HtNodeLink | undefined {
    const targetHandle = edge?.targetHandle
    return targetHandle ? this.createNodeLink(targetHandle) : undefined
  }

  private createNodeLink(target: string): HtNodeLink {
    const [type, id] = target.split('_')
    const targetNodeType = this.getContentTypeFromString(type)
    return { id, type: targetNodeType }
  }

  private getContentTypeFromString(type: string): HtContentType {
    const functionContentTypes: string[] = [
      ContentType.QUEUE_STATUS,
      ContentType.CHANNEL,
      ContentType.COUNTRY_CONDITION,
      ContentType.CUSTOM_CONDITION,
    ]
    if (functionContentTypes.includes(type)) {
      return HtContentType.FUNCTION
    }
    return type as HtContentType
  }
}
