import { memo } from 'react'

import { ChannelNodeProps } from '../../types'
import { Icon } from '../base'
import { Condition, Handles, NodeHeader } from './node-components'
import { Border, ConditionalNode, StyledList } from './node-styles'

const ChannelNode = ({ data, selected, id }: ChannelNodeProps) => {
  return (
    <ConditionalNode $isSelected={selected}>
      <Handles contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Channel'} icon={Icon.SPLIT} />
      <StyledList>
        {data.channels.map((channel, i) => (
          <Condition
            key={i}
            id={id + '-' + channel.id}
            icon={channel.id}
            border={i === 0 ? Border.NONE : Border.TOP}
          >
            {channel.name}
          </Condition>
        ))}
        <Condition id={id + '-default'} border={Border.TOP}>
          Any other channels
        </Condition>
      </StyledList>
    </ConditionalNode>
  )
}

export default memo(ChannelNode)
